.row-input {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem 0;
}

.button {
  position: relative;
  top: 50%;
  margin: 0 1rem;
}

.Card-input {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  margin-right: 0 1rem;
  box-shadow: 5px 10px 20px rgba(0,0,0,0.01), 2px 6px 6px rgba(0,0,0,0.05);
}

.dragger {
  text-align: center;
  margin-top: 1rem;
  margin-right: 0 1rem;
  box-shadow: 5px 10px 20px rgba(0,0,0,0.01), 2px 6px 6px rgba(0,0,0,0.05);
}

.form-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.bulk-input {
  margin: 1.2rem 0;
}

.dataform-button {
  margin: 0.5rem 0;
}

.dataform-input {
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.progress-bar {
  margin: 0 1rem;
}
