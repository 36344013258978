.row-table {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.table-col-text {
  font-size: 1rem;
}

@media only screen and (max-width: 992px) {
  .table-col-text {
    font-size: 0.7rem;
  }
}


.Plot-Select {
  width: 120px;
}

.table-controls {
  min-height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.table-controls-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}

@media only screen and (max-width: 992px) {
  .Controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.Tooltip {
  margin: 5px;
}

.HelpText {
  margin: 5px;
}
