
.App {
  text-align: center;
}

.App-header {
  color: white;
  font-size: 1.1rem;
}

.ant-header {
  padding: 0 1.1rem;
}

.App-header-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.App-header-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.App-header-link-name {
  color: white;
}

.App-header-link {
  color: white;
  margin: 0 1rem;
}

@media only screen and (max-width: 767px) {
  .App-header-desktop {
    display: none;
  }

  .App-header-link {
    color: white;
    margin: 0 0;
  }

  .App-header-mobile {
    font-size: 0.9rem;
  }
}

@media only screen and (min-width: 768px) {
  .App-header-mobile {
    display: none;
  }
}

.App-content {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  padding: 10vh 10vw;
}

.App-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  margin-top: auto;
  background-color: #01101F;
}

.app-footer-text {
  text-align: center;
  margin: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
