.row-table {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Download-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.Plot-Select {
  width: 120px;
}

.Controls {
  height: 100px;
}

.Tooltip {
  margin: 5px;
}

.HelpText {
  margin: 5px;
}
