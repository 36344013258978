.Card-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Plot {
  background-color: white;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  border-color: rgba(0,0,0,0.05);
  border-style: solid;
  border-width: 1px;
  box-shadow: 5px 10px 20px rgba(0,0,0,0.01), 2px 6px 6px rgba(0,0,0,0.05);
}

.PlotNone {
  background-color: #F3F3F3;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.Plot-Select {
  width: 300;
}

.Plot-Settings-Row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 1rem;
  min-height: 40px;
  width: 100%;
}

.Plot-Control-Buttons {
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.Plot-Settings-Labels {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
